<template>
  <v-app id="inspire">
    <Navigation></Navigation>
    <router-view></router-view>
    <Footer></Footer>
  </v-app>
</template>

<script>
  import Navigation from "@/components/Navigation";
  import Footer from "@/components/Footer";

  export default {
    name: 'app',
    components: {
      Footer,
      Navigation,
    },
    mounted() {

    }
  }
</script>
