<template>
  <v-main>
    <v-container>
      <h5 class="text-h5">Go-Ahead Traffic Information</h5>
    <v-alert
            v-if="messages.loading"
    >
      Loading...
    </v-alert>
    <v-alert
            v-if="messages.timeout"
            type="error"
    >
      The request timed out. Please check your internet connection.
    </v-alert>
      <v-alert
              v-if="messages.server_error"
              type="error"
      >
        An internal server error occurred.
      </v-alert>
      <template
              v-if="!messages.loading && !info.text.length"
      >
        <v-alert
          type="success"
        >
          Have a nice trip - there is no traffic information!
        </v-alert>
      </template>
      </v-container>
      <v-container v-if="info.text.length">
        <h4 class="font-weight-bold">{{info.title[0]}}</h4>
        <p v-for="item in info.text" v-bind:key="item">
          {{item}}
        </p>

    </v-container>
    <v-container>
      <v-alert
        type="info"
      >
        Information source: Go-Ahead Baden-Württemberg (https://www.go-ahead-bw.de/unterwegs-mit-go-ahead/fahrplanabweichungen.html)
      </v-alert>
    </v-container>
    <v-container>
        <v-btn
                elevation="2"
                href="https://twitter.com/bahn_bw_info"
                target="_blank"
        >
          <v-icon
            left
          >
            mdi-twitter
          </v-icon>
          Twitter Bot with Traffic Info Update
        </v-btn>
    </v-container>
  </v-main>
</template>

<script>
  export default {
    data: function () {
      return {
        info: {
          title: "",
          lines: [],
        },
        messages: {
          timeout: false,
          server_error: false,
          loading: false,
          success: false,
        }
      }
    },
    mounted: function () {
      this.update();
      setInterval(function () {
        this.update();
      }.bind(this), 180000);
    },
    methods: {
      update: function () {
        this.messages.loading = true;
        this.axios
                .get('/api/getGABWInterruptions')
                .then(response => {
                  this.info = response.data;
                  this.messages.success = true;
                  this.messages.loading = false;
                  this.messages.timeout = false;
                  this.messages.server_error = false;
                })
                .catch(error => {
                  this.messages.loading = false;
                  this.messages.timeout = false;
                  this.messages.server_error = false;
                  if (typeof error.response !== 'undefined') {
                    if (error.response.status == 500) {
                      this.messages.server_error = error;
                      this.messages.success = false;
                    } else {
                      this.messages.timeout = error;
                    }
                  } else {
                    this.messages.timeout = error;
                  }
                })
      }
    },
    name: 'GABWInformation',
  }
</script>

<style scoped>
  .is-cnl {
    text-decoration: line-through;
  }
</style>
