<template>
  <v-main>
    <v-container>
      <h5 class="text-h5">Settings</h5>
    </v-container>
    <v-container>
      <v-text-field
              label="GABW API URL"
              v-model="selectedTrain"
              type="url"
      ></v-text-field>
    </v-container>

  </v-main>
</template>

<script>
  export default {
    name: 'Settings'
  }
</script>

