<template>
  <div>
    <v-navigation-drawer
            v-model="drawer"
            app
    >
      <v-list nav dense>
        <v-list-item link to="/">
          <v-list-item-action>
            <v-icon>mdi-home</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Start</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
          <v-list-item link to="/gabw_info">
            <v-list-item-action>
              <v-icon>mdi-information</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Traffic Interruptions</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/gabw_changes">
            <v-list-item-action>
              <v-icon>mdi-clipboard-clock</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Planned Changes</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link href="https://marudor.de" target="_blank">
            <v-list-item-action>
              <v-icon>mdi-open-in-new</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Marudor.de</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link href="https://travelynx.de" target="_blank">
            <v-list-item-action>
              <v-icon>mdi-open-in-new</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Travelynx</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/about">
            <v-list-item-action>
              <v-icon>mdi-book-open-variant</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>About</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        <v-list-item link to="/settings">
          <v-list-item-action>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
          <v-list-item link to="/legal">
            <v-list-item-action>
              <v-icon>mdi-format-section</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Legal</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
            app
            color="primary"
            dark
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>UTS Beta</v-toolbar-title>
    </v-app-bar>
  </div>
</template>

<script>

  export default {
    name: 'Menu',

    data: () => ({
      drawer: null,
    }),
  }
</script>

